<template>
    <v-footer absolute app>
        <v-container class="pt-0">
            <v-row>
                <v-divider />
            </v-row>
            <v-row>
                <v-col cols="12" sm="8" />
                <v-col>
                    <v-list density="compact" color="info" nav>
                        {{ ""/* :active=false is a hack to disable higlighting */ }}
                        <v-list-item :active="false" href="https://airtable.com/shrDJxMEnnbmAZhIn"
                            target="_blank">Feedback</v-list-item>
                        <v-list-item :active="false" :to="{ name: 'docs-slug' }">Documentation</v-list-item>
                        <v-list-item :active="false" :to="{ name: 'support-us' }">Support us</v-list-item>
                        <v-list-item :active="false" :to="{ name: 'index' }">Home</v-list-item>
                    </v-list>
                </v-col>
                <v-col class="text-center pt-0" cols="12">
                    {{ new Date().getFullYear() }} — <strong>PleaseTalk</strong>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script lang="ts">
import { useDisplay } from 'vuetify';

export default {
    setup() {
        const { mobile } = useDisplay();
        return { mobile }
    }
}
</script>